// src/auth/AuthContext.tsx

import { createContext, useState, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import api from "@beeldit/core/helpers/api";
import { User } from "../users/models/User";
import authInterceptor from "./interceptors/AuthInterceptor";

interface AuthContextType {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  tokenLoaded: boolean;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

export const AuthContext = createContext<AuthContextType>({
  token: null,
  setToken: () => {},
  tokenLoaded: false,
  user: null,
  setUser: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);
  const [tokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("panel")) {
      const token = localStorage.getItem("token");
      setToken(token);
      api.interceptors.request.use(authInterceptor(() => token));
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
      }
      setUser(user);
      setToken(token);
      setTokenLoaded(true);
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{ token, setToken, tokenLoaded, user, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
