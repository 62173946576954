import { useNavigate } from "react-router-dom";

function useErrorHandler() {

  const navigate = useNavigate();
  return (error: any, setBackendFormErrors: any = null) => { 
    if(error.response.status === 401) {
        navigate('/login');
      } else if(error.response.status === 422) {
        let formattedError = formatErrors(error.response.data.errors);
        setBackendFormErrors(formattedError);
      }
  }
}

function formatErrors(errors: any) {
    let formattedErrors: any = {};
    for (let key in errors) {
      formattedErrors[key] = {__errors: errors[key] };        
    }    
    return formattedErrors;
}

export default useErrorHandler;