import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import api from "@beeldit/core/helpers/api";
import { t } from "i18next";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center" as const,
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const host = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "";
  const response = await api.post(`${host}/files/upload`, formData, config);

  return response.data;
};

function BeelditFileInput(props: any) {
  const {
    id,
    options,
    value = [],
    required,
    disabled,
    readonly,
    onChange,
  } = props;

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      try {
        const newFiles = await Promise.all(
          acceptedFiles.slice(0, props.maxFiles).map(uploadFile)
        );
        onChange([...value, ...newFiles]);
      } catch (error) {
        console.error("Upload failed", error);
      }
    },
    [value, onChange]
  );

  const removeFile = (fileUrl: string) => {
    onChange(value.filter((file: any) => file.url !== fileUrl));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{t("Drop the files here ...")}</p>
        ) : (
          <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
        )}
      </div>
      <ul>
        {value
          ? value.map((file: any) => (
              <li key={file.url}>
                {file.filename}{" "}
                <span
                  onClick={() => removeFile(file.url)}
                  style={{ color: "red" }}
                >
                  X
                </span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

BeelditFileInput.defaultProps = {
  maxFiles: 1,
};

export default BeelditFileInput;
