import { ObjectFieldTemplateProps, RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import JSONSchemaForm from '@rjsf/core';

interface Prop {
    formConfig: {
        element: any,
        schema: any,
        uiSchema?: RJSFSchema | undefined,
        widgets?: any,
        onChange: any,
    },
}

function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <div className='form-row'>
      {props.title}
      {props.description}
      {props.properties.map((element) => (
        <div className='property-wrapper'>{element.content}</div>
      ))}
    </div>
  );
}


function CrudModuleFactoryTableFilters(props: Prop) {

    const {element, schema, onChange, uiSchema, widgets} = props.formConfig;    
  
    const defaultScheme: UiSchema = {
      // "ui:globalOptions" : {
      //   "label": false,
      // },
      "ui:submitButtonOptions": {
        "props": {
          "disabled": false,
          "className": "btn btn-info"
        },
        "norender": true,
      }
    };
    const log = (type: any) => console.log.bind(console, type);

    const mergedUiScheme = {...uiSchema, ...defaultScheme};

    return (
      <JSONSchemaForm
        schema={schema}
        uiSchema={mergedUiScheme}
        formData={element}
        validator={validator}
        onChange={onChange}
        widgets={widgets ? widgets : {}}
        onError={log('errors')}
        templates={{ ObjectFieldTemplate }}
      />            
    )
}

export default CrudModuleFactoryTableFilters;
