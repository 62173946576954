import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
  icon?: any;
}

interface SidebarProps {
  items: MenuItem[];
}

function createMenuItem(item: MenuItem) {
  if (item.children) {
    return (
      <NavDropdown title={item.label} key={item.to}>
        {item.children.map(createMenuItem)}
      </NavDropdown>
    );
  }

  return (
    <Nav.Item key={item.to}>
      <NavLink to={item.to} className="nav-link hover-translate text-black">
        <FontAwesomeIcon icon={item.icon} className="me-2"/> {item.label}
      </NavLink>
    </Nav.Item>
  );
}

function Sidebar(props: SidebarProps) {
  const { items} = props;

  return (
      <Nav className="flex-column sidebar d-none d-lg-block p-3">
      {items.map(createMenuItem)}
    </Nav>
  );
}

export default Sidebar;
