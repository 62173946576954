import { MouseEventHandler, useContext } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

interface Context {
    showDeleteConfirmationModal: boolean, 
    setShowDeleteConfirmationModal: (showModal: boolean) => MouseEventHandler
}
interface ModalConfig {
    context: any,
    onSubmit: any,
}

interface Props {
    modalConfig: ModalConfig,
    children: React.ReactNode
}

function CrudModuleFactoryConfirmationModal(props: Props) {    

    const {context, onSubmit} = props.modalConfig;
    const {children} = props;
    const {showDeleteConfirmationModal, setShowDeleteConfirmationModal} = useContext<Context>(context);
    const handleClose = () => setShowDeleteConfirmationModal(false); // We pass the function to close the modal on success

    return (
    <>
        <Modal show={showDeleteConfirmationModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-dark" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
                Delete
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )    
}

export default CrudModuleFactoryConfirmationModal;