import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import login from "../services/login";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import api from "@beeldit/core/helpers/api";
import AuthInterceptor from "../interceptors/AuthInterceptor";

const authInterceptor = AuthInterceptor(() => localStorage.getItem("token"));

let interceptorId: number | null = null;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken, setUser } = useContext(AuthContext);

  useEffect(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, []);

  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    setToken(data.token);
    setUser(data.user);
    api.interceptors.request.use(authInterceptor);
    navigate("/panel");
  };

  const handleLogin = async (e: any) => {
    login({ email: email, password: password }, onSuccess);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <img
        src="logo.png"
        alt="App Icon"
        style={{ maxWidth: "10%", height: "auto", marginBottom: "3rem" }}
      />
      <div className="card" style={{ width: "18rem" }}>
        <div className="card-body">
          <h5 className="card-title text-center mb-4">Iniciar sesión</h5>
          <form>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Contraseña:
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <button
              onClick={handleLogin}
              type="button"
              className="btn btn-primary w-100"
            >
              Iniciar sesión
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
